const isProduction = process.env.NODE_ENV === "production";

export const BASE_URL = isProduction ? "https://api.neusort.com/" : "http://localhost:5005/";

export const HOST_NAMES = ["neusort.com", "hyrr.app", "techyrr.com", "localhost"];

export const TEST_IPS = ["20.193.143.183", "20.197.39.7", "20.40.47.123", "20.198.57.161"];

export const adminViewAssignmentDir = "/opt/admin/assignments/";

export const licenceTypes = {
  v1: "Ultra",
  v2: "Pro",
};

export const techyrrCompanyId = isProduction ? "tdb8c32bde588491" : "nbbeab6f1584da58";
export const techyrrInviteId = isProduction ? "45117325-552c-438f-9c27-440ae473d240" : "ce57c194-d590-45bd-8a41-52f3cf763d6d";

export const products = [
  {
    title: "EvalAI",
    href: "https://neusort.com/product/eval-ai",
    description: "AI Interviews",
  },
];

export const PUBLIC_RAZORPAY_API_KEY = isProduction
  ? "rzp_live_K5a0N6bOmYFW7O"
  : "rzp_test_oDrMzpIYkooNTt";

export const PUBLIC_B2C_RAZORPAY_API_KEY = isProduction
  ? "rzp_live_3awdguwmCGNWyn"
  : "rzp_test_oDrMzpIYkooNTt";

export const restrictedDomains = [
  "mail.com",
  "gmail.com",
  "hotmail.com",
  "yahoo.com",
  "outlook.com",
  "icloud.com",
  "aol.com",
  "zoho.com",
  "protonmail.com",
  "yandex.com",
  "live.com",
  "me.com",
  "msn.com",
  "gmx.com",
  "inbox.com",
];

export const b2cTechyrrDomains = ["www.techyrr.com", "techyrr.com"];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
